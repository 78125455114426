body {
  /* overflow-x: hidden; */
}

/* @media screen and (max-width: 1000px) {
    body {
        overflow-x: auto;
    }
} */

:global #root {
  width: 100rem;
  margin: 0 auto;
}

img {
  display: block;
}

.wrap {
  /* width: 19.2rem; */
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.scanCode {
  position: fixed;
  /* width: 160px; */
  height: 13.125rem;
  background: #f54343;
  border-radius: 0.3125rem;
  right: 1.5625rem;
  top: 3.125rem;
  padding: 0 0.520833rem;
}

.scanCode .close {
  position: absolute;
  top: -1.875rem;
  right: 0;
  height: 1.458333rem;
  line-height: 1.458333rem;
  font-family: PingFangSC-Regular;
  font-size: 1.041667rem;
  color: #9e9e9e;
  letter-spacing: 0.89px;
  cursor: pointer;
}

:global(.hairlines) .scanCode .close {
  letter-spacing: 0.5px;
}

.scanCode .title {
  height: 1.5625rem;
  line-height: 1.5625rem;
  font-family: PingFangSC-Regular;
  font-size: 1.119792rem;
  color: #ffffff;
  letter-spacing: 0.8px;
  margin: 0 auto;
  margin-top: 0.520833rem;
}

:global(.hairlines) .scanCode .title {
  letter-spacing: 0.5px;
}

.scanCode .code > img {
  width: 8.333333rem;
  height: 8.333333rem;
  margin: 0 auto;
  margin-top: 0.520833rem;
}

.scanCode .tip {
  /* width: 160px; */
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 0.520833rem;
}

.scanCode .tip .sys {
  width: 1.041667rem;
  height: 1.041667rem;
  background-image: url(./images/sys.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.scanCode .tip .sysDown {
  font-family: PingFangSC-Regular;
  font-size: 0.911458rem;
  color: #ffffff;
  letter-spacing: 0.8px;
  margin-left: 0.416667rem;
}

:global(.hairlines) .scanCode .tip .sysDown {
  letter-spacing: 0.5px;
}

.top {
  position: relative;
  width: 100%;
  padding-top: 9.583333rem;
  height: 43.541667rem;
  min-width: 75rem;
  /* background-image: url(./images/top1.png); */
  background-image: url(./images/top_bg_new.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.top .box {
  width: 64.895833rem;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.top .box .top_left {
  top: 9.583333rem;
  width: 31.770833rem;
}

.top .box .top_left img.logo {
  width: 100%;
  height: 3.645833rem;
}

.top .box .top_left .homeLine {
  width: 100%;
  height: 0.104167rem;
  background: -webkit-gradient(
                    linear,
                    left top, right top,
                    from(rgba(255, 255, 255, 0)),
                    color-stop(50%, rgba(255, 255, 255, 1)),
                    to(rgba(255, 255, 255, 0))
                );
  background: -webkit-linear-gradient(
                    left,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 1) 50%,
                    rgba(255, 255, 255, 0) 100%
                );
  background: linear-gradient(
                    90deg,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 1) 50%,
                    rgba(255, 255, 255, 0) 100%
                );
  margin: 0 auto;
  margin-top: 1.5625rem;
}

.top .box .top_left .title {
  height: 1.5625rem;
  line-height: 1.5625rem;
  font-family: PingFangSC-Regular;
  font-size: 1.25rem;
  color: #ffffff;
  letter-spacing: 0.283854rem;
  margin-top: 0.572917rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.top .box .top_left .title .dian {
  width: 0.260417rem;
  height: 0.260417rem;
  color: #fff;
  margin: 0 0.677083rem;
}

.top .box .top_left img.code {
  width: 8.854167rem;
  height: 8.854167rem;
  margin: 0 auto;
  margin-top: 5.729167rem;
}

.top .box .top_left .smDownLoad {
  width: 13.020833rem;
  height: 3.125rem;
  border: 1px solid rgba(255, 255, 255, .3);
  border-radius: 0.317708rem;
  margin: 0 auto;
  margin-top: 2.291667rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-self: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

:global(.hairlines) .top .box .top_left .smDownLoad {
  border: 0.5px solid rgba(255, 255, 255, .3);
}

.top .box .top_left .smDownLoad img {
  width: 1.875rem;
  height: 1.875rem;
  margin-left: 1.25rem;
}

.top .box .top_left .smDownLoad .kfhws {
  font-family: PingFangSC-Regular;
  font-size: 1.875rem;
  color: #ffffff;
  letter-spacing: 0;
  margin-left: 0.9375rem;
}

.top .box .top_right {
  margin-top: -9.583333rem;
  margin-left: 5.833333rem;
}

.top .box .top_right img {
  display: block;
  width: 27.291667rem;
}

.center {
  position: relative;
  width: 100%;
  min-width: 75rem;
}

.center > .headTitle {
  position: absolute;
  /* top: 50px; */
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  /* width: 473px; */
  height: 3.541667rem;
  font-family: PingFangSC-Semibold;
  font-size: 2.864583rem;
  color: #212223;
  letter-spacing: 0.8px;
  text-align: center;
  margin: 0 auto;
}

:global(.hairlines) .center > .headTitle {
  letter-spacing: 0.5px;
}

.center > img {
  width: 100%;
}

.center .content {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  color: #66718b;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: -8.802083rem;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.center .content > img {
  margin: 0 auto;
}

.video_player {
  position: absolute;
  margin-left: 59.0625rem;
  margin-top: -4.010417rem;
  display: block;
  border: none;
  -o-object-fit: fill;
  object-fit: fill;
  /* outline: none; */
  /* border: 1px solid red; */
  width: 19.583333rem;
  /* height: 744px; */
  border-radius: 1.458333rem;
}

.bottom {
  width: 100%;
  min-width: 75rem;
  background-color: #fff;
  padding-bottom: 2.604167rem;
  padding-top: 1.041667rem;
}

.bottom .bottomContent {
  width: 96.822917rem;
  margin: 0 auto;
  margin-top: 2.604167rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.bottom .bottomContent .left {
  position: relative;
  width: 29.010417rem;
  height: 50.625rem;
  margin-left: 3.177083rem;
}

.bottom .bottomContent .left .timeZhow {
  position: absolute;
  width: 0.104167rem;
  height: 50.625rem;
  background-image: url('./images/timezhou.png');
  background-repeat: no-repeat;
  background-size: contain;
  left: 0.989583rem;
}

.bottom .bottomContent .left .leftContent {
  position: absolute;
  width: 100%;
  margin-top: 1.770833rem;
}

.bottom .bottomContent .left .leftContent .left_item {
  width: 100%;
  margin-bottom: 1.666667rem;
}

.bottom .bottomContent .left .leftContent .left_item .time {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 1.875rem;
  line-height: 2.083333rem;
  background: #fcf5e8;
  border: 0.104167rem solid #ffffff;
  border-radius: 1.041667rem;
  justify-self: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.bottom .bottomContent .left .leftContent .left_item .time .cycle {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url('./images/normal_cycle.png');
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 0.3125rem;
}

.bottom .bottomContent .left .leftContent .left_item .time .timeText {
  font-family: DINAlternate-Bold;
  font-size: 1.041667rem;
  color: #c5a15f;
  letter-spacing: 0;
  margin-left: 0.625rem;
}

.bottom .bottomContent .left .leftContent .left_item .activeTime {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 1.875rem;
  line-height: 2.083333rem;
  background: #fee3e3;
  border: 0.104167rem solid #ffffff;
  border-radius: 1.041667rem;
  justify-self: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.bottom .bottomContent .left .leftContent .left_item .activeTime .cycle {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url('./images/active_cycle.png');
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 0.3125rem;
}

.bottom .bottomContent .left .leftContent .left_item .activeTime .timeText {
  font-family: DINAlternate-Bold;
  font-size: 1.041667rem;
  color: #f54343;
  letter-spacing: 0;
  margin-left: 0.625rem;
}

.bottom .bottomContent .left .leftContent .left_item .title {
  height: 2.1875rem;
  height: 2.1875rem;
  font-family: PingFangSC-Semibold;
  font-size: 1.5625rem;
  color: #4d4d4d;
  letter-spacing: 0;
  padding-left: 2.1875rem;
  margin-top: 0.677083rem;
}

.bottom .bottomContent .left .leftContent .left_item .activeTitle {
  height: 2.1875rem;
  height: 2.1875rem;
  font-family: PingFangSC-Semibold;
  font-size: 1.5625rem;
  color: #212223;
  letter-spacing: 0;
  padding-left: 2.1875rem;
  margin-top: 0.677083rem;
}

.bottom .bottomContent .left .leftContent .left_item .summary {
  width: 26.822917rem;
  height: 4.375rem;
  font-family: PingFangSC-Regular;
  font-size: 1.041667rem;
  color: #949494;
  letter-spacing: 0;
  padding-left: 2.1875rem;
  margin-top: 0.3125rem;
  text-align: justify;
}

.bottom .bottomContent .left .leftContent .left_item .activeSummary {
  width: 26.822917rem;
  height: 4.375rem;
  font-family: PingFangSC-Regular;
  font-size: 1.041667rem;
  color: #212223;
  letter-spacing: 0;
  padding-left: 2.1875rem;
  margin-top: 0.3125rem;
  text-align: justify;
}

.bottom .bottomContent .left .leftContent .left_item:nth-child(1) .timeWid {
  width: 9.114583rem;
}

.bottom .bottomContent .left .leftContent .left_item:nth-child(2) .timeWid {
  width: 9.114583rem;
}

.bottom .bottomContent .left .leftContent .left_item:nth-child(3) .timeWid {
  width: 9.114583rem;
}

.bottom .bottomContent .left .leftContent .left_item:nth-child(4) .timeWid {
  width: 9.114583rem;
}

.bottom .bottomContent .right {
  width: 64.739583rem;
  height: 50.625rem;
  margin-left: 1.666667rem;
}

.bottom .bottomContent .right > img {
  width: 100%;
}

.foot {
  width: 100%;
  height: 8.854167rem;
  min-height: 8.854167rem;
  background: #f54343;
  min-width: 75rem;
  font-size: 0.729167rem;
  color: #ffffff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-self: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: PingFangSC-Regular;
}

.foot .footInner {
  text-align: center;
  margin: 0 auto;
}

.foot .footInner .footLogo {
  width: 18.229167rem;
  height: 1.979167rem;
  margin: 0 auto;
}

.foot .footInner .footLogo > img {
  display: block;
  width: 18.229167rem;
  height: 1.979167rem;
}

.foot .footInner .company {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 1.041667rem;
}

.foot .footInner .copyright {
  margin-top: 0.364583rem;
  text-align: center;
}

.headTitle {
  /* width: 473px; */
  height: 3.541667rem;
  font-family: PingFangSC-Semibold;
  font-size: 2.864583rem;
  color: #212223;
  letter-spacing: 0.8px;
  text-align: center;
  margin: 0 auto;
}

:global(.hairlines) .headTitle {
  letter-spacing: 0.5px;
}